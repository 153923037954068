import { $post } from '@js/request'
import { compress } from '@src/assets/js/tool'
import { USERINFO } from '@src/redux/actions/basic'

export async function loginRequest (username: string, password: string, verification: string): Promise<{ userInfo: USERINFO, token: string }> {
  const res = await $post('/api/common/vuser_login', {
    verify_plus: 'sms',
    verification,
    username,
    password: compress(password)
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
