import { formatDate } from '@src/assets/js/tool'
import { FaPiaoSchema } from '@src/components/Schema/FaPiaoSchema'
import React, { ReactElement, useMemo } from 'react'
import style from './Index.module.less'
import { Button, Space } from 'antd'
import { useHistory } from 'react-router-dom'
import { FileSchema } from '@src/components/Schema/FileSchema'

interface Props {
  data: FaPiaoSchema
  caseid: string
  setDetail: (v: FaPiaoSchema) => void
  index: number
  setVisible: (v: boolean) => void
}

function filterEmpty (v: any): any {
  if (v === null || v === '' || v === undefined) {
    return '无'
  }
  return v
}

function Item ({ data, index, caseid, setVisible, setDetail }: Props): ReactElement {
  const h = useHistory()
  const doc = useMemo<FileSchema[]>(() => {
    if (data.fapiao_file !== null && data.fapiao_file instanceof Array) {
      return data.fapiao_file
    }
    return []
  }, [data])
  const status = useMemo(() => {
    if (data.status === '已提交') {
      return '审核中'
    }
    return data.status
  }, [data])
  const isFail = useMemo(() => {
    return data.status === '审核不通过'
  }, [data])
  const actions = useMemo(() => {
    return data !== null && data.actions instanceof Array ? data.actions : []
  }, [data])
  return (
    <div className={style.container}>
      <div className={style.order}>
        <span>{index + 1}</span>
      </div>
      <div className={style.block}>
        {renderApplicant(data)}
        {renderProcess(data)}
        <div className={style.line}>
          <span className={style['line-label']}>状态</span>
          <span className={style['line-value']}>
            <span className={style['line-status']}>{status === '完成' && doc.length === 0 ? '待开票' : status }</span>
            <Space>
              {actions.length > 0 && <Button onClick={() => { setDetail(data); setVisible(true) }}>详情</Button>}
              {isFail && <Button type='primary' onClick={() => h.push(`/admin/case-detail/${caseid}/fapiao/${data.id}`)}>重新提起</Button>}
            </Space>
          </span>
        </div>
        {
          doc.length > 0 && (
            <div className={style.line}>
              <span className={style['line-label']}>开具发票成功</span>
              <span className={style['line-value']}>
                {doc.map((li, i) => {
                  return (
                    <span className={style.file} key={i}>
                      <a href={li.url} >{li.original_filename}</a>
                    </span>
                  )
                })}
              </span>
            </div>
          )
        }
      </div>
    </div>
  )
}

function renderApplicant (data: FaPiaoSchema): ReactElement {
  const l = (data.actions instanceof Array ? data.actions : []).filter(li => li.type === 'submit').sort((a, b) => a.create_datetime - b.create_datetime)
  if (l.length === 0) {
    return (
      <>
        <div className={style['block-header']}>
          <span className={style['block-header-left']}>
            <span className={style['block-label']}>申请人</span>
            <span>{data.applicant}</span>
          </span>
          <span className={style['block-date']}>{formatDate(data.submit_datetime, 'YYYY-MM-DD HH:mm')}</span>
        </div>
        <div className={style.content}>
          <span className={style['content-label']}>开票类型</span>
          <span className={style['content-value']}>{filterEmpty(data.subtype)}</span>
        </div>
        <div className={style.content}>
          <span className={style['content-label']}>开票金额</span>
          <span className={style['content-value']}>{filterEmpty(data.exvars_fee)}</span>
        </div>
        <div className={style.content}>
          <span className={style['content-label']}>申请事由</span>
          <span className={style['content-value']}>{filterEmpty(data.content)}</span>
        </div>
        <div className={style.line}>
          <span className={style['line-label']}>上传材料</span>
          <span className={style['line-value']}>
            {data.file instanceof Array ? (data.file.length === 0 && '无') : '无'}
            {(data.file instanceof Array ? data.file : []).map((li, i) => {
              return (
                <span className={style.file} key={i}>
                  <a href={li.url} >{li.original_filename}</a>
                </span>
              )
            })}
          </span>
        </div>
      </>
    )
  }
  const o = l[l.length - 1]
  return (
    <>
      <div className={style['block-header']}>
        <span className={style['block-header-left']}>
          <span className={style['block-label']}>申请人</span>
          <span>{o.operator}</span>
        </span>
        <span className={style['block-date']}>{formatDate(o.create_datetime, 'YYYY-MM-DD HH:mm')}</span>
      </div>
      <div className={style.content}>
        <span className={style['content-label']}>开票金额</span>
        <span className={style['content-value']}>{filterEmpty(o.exvars_fee)}</span>
      </div>
      <div className={style.content}>
        <span className={style['content-label']}>开票类型</span>
        <span className={style['content-value']}>{filterEmpty(o.subtype)}</span>
      </div>
      <div className={style.content}>
        <span className={style['content-label']}>申请事由</span>
        <span className={style['content-value']}>{filterEmpty(o.content)}</span>
      </div>
      <div className={style.line}>
        <span className={style['line-label']}>上传材料</span>
        <span className={style['line-value']}>
          {o.file instanceof Array ? (o.file.length === 0 && '无') : '无'}
          {(o.file instanceof Array ? o.file : []).map((li, i) => {
            return (
              <span className={style.file} key={i}>
                <a href={li.url} >{li.original_filename}</a>
              </span>
            )
          })}
          {
            (!(o.file instanceof Array) || o.file.length === 0) && <span>无</span>
          }
        </span>
      </div>
    </>
  )
}

function renderProcess (data: FaPiaoSchema): ReactElement {
  if (data.status !== '审核不通过') {
    return <span />
  }
  const l = (data.actions instanceof Array ? data.actions : []).filter(li => li.type === 'process').sort((a, b) => a.create_datetime - b.create_datetime)
  if (l.length !== 0) {
    const o = l[l.length - 1]
    return (
      <>
        <div className={style['block-header']}>
          <span className={style['block-header-left']}>
            <span className={style['block-label']}>审批人</span>
            <span>{o.operator}</span>
          </span>
          <span className={style['block-date']}>{formatDate(o.create_datetime, 'YYYY-MM-DD')}</span>
        </div>
        <div className={style.content}>
        <span className={style['content-label']}>回复</span>
        <span className={style['content-value']}>{o.content}</span>
      </div>
      </>
    )
  }
  return <span />
}

export default Item
