import React, { ReactElement, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { Anchor, Menu, Dropdown } from 'antd'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { History } from 'history'
import style from './Index.module.less'
import classNames from 'classnames'
import { OperateItem } from '../../ajax'
import { confirmArbitrator } from './tool'

const { Link } = Anchor

interface Props {
  data: CaseSchema
  unuse: OperateItem[]
  used: OperateItem[]
  refreshOpereate: (k: string) => void
}

// 操作对应方法
function opereateFn (k: string, h: History, o: CaseSchema, refreshOpereate: (k: string) => void): void {
  if (k === 'party_dabian') {
    h.push(`/admin/case-detail/${o.id}/defince`)
  } else if (k === 'party_zuting') {
    confirmArbitrator(refreshOpereate, h)
  } else if (k === 'party_shengxiaozhengming') {
    h.push(`/admin/case-detail/${o.id}/effectiveness`)
  } else if (k === 'party_fapiao') {
    h.push(`/admin/case-detail/${o.id}/fapiao`)
  } else if (k === 'party_tuifei') {
    h.push(`/admin/case-detail/${o.id}/tuifei`)
  }
}

function renderMenu (unuse: OperateItem[], o: CaseSchema, h: History, refreshOpereate: (k: string) => void): ReactElement {
  return (
    <Menu>
      {
        unuse.map(li => {
          return <Menu.Item key={li.key} onClick={() => opereateFn(li.key, h, o, refreshOpereate)}>{li.title}</Menu.Item>
        })
      }
    </Menu>
  )
}

function OperateMenu ({ data, unuse, refreshOpereate }: Props): ReactElement {
  const h = useHistory()
  if (unuse.length === 0) {
    return <span />
  }
  return (
    <Dropdown overlay={renderMenu(unuse, data, h, refreshOpereate)} placement="bottomCenter" arrow>
      <a className={style['operate-btn']}>
        <span>添加操作</span>
        <DownOutlined />
      </a>
    </Dropdown>
  )
}

function Main ({ data, unuse, used, refreshOpereate }: Props): ReactElement {
  const [isFixed, setIsFixed] = useState(window.document.documentElement.offsetWidth >= 1280)
  useEffect(() => {
    const fn = function (): void {
      setIsFixed(window.document.documentElement.offsetWidth >= 1280)
    }
    window.addEventListener('resize', fn)
    return () => {
      window.removeEventListener('resize', fn)
    }
  }, [])
  return (
    <div className={classNames(style.container, { [style.fixed]: isFixed })}>
      <Anchor className={style.anchor} targetOffset={88}>
        <Link href='#info' title='仲裁申请书' />
        {used.map((li, i) => <Link key={i} href={`#${li.key}`} title={li.title} />)}
      </Anchor>
      <OperateMenu data={data} unuse={unuse} used={used} refreshOpereate={refreshOpereate} />
    </div>
  )
}

export default Main
