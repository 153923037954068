import { ReactElement } from 'react'

interface Props {
  value?: string
  format?: (v: any) => string
}

function Main ({ value, format }: Props): ReactElement {
  if (typeof format === 'function') {
    return <span>{format(value)}</span>
  }
  return (
    <span>{value !== undefined && value !== '' ? value : '无'}</span>
  )
}

export default Main
