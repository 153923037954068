import React, { ReactElement, useState, useEffect, useRef, RefObject } from 'react'
import { Form, FormInstance, Input, Select, Space, Button, message, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { History } from 'history'
import { connect } from 'react-redux'
import { match } from 'react-router-dom'
import _ from 'lodash'
import { CaseSchema } from '@components/Schema/CaseSchema'
import Dictionary, { DictionaryItem } from '@js/dictionary'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import backIcon from '@imgs/back-arrow.png'
import style from './Index.module.less'
import PartyList from './components/PartyList/Index'
import { AgentSchema } from '@src/components/Schema/AgentSchema'
import { awaitWrap, scrollToFirstError } from '@js/tool'
import { delAgentRequest, agentRequest } from './ajax'
import { initIsSelf } from '../../tool'
import IdentityCard from '../../components/IdentityCard/Index'

const { Item } = Form
const { Option } = Select
const { TextArea } = Input
const { confirm } = Modal

interface Params {
  caseid: string
  type: string
  id?: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
  dictionary: Dictionary
  userInfo: USERINFO
  refreshCase: () => {}
}

function initData (caseDetail: CaseSchema, type: string, id: string | undefined, formRef: RefObject<FormInstance>, setAgent: (v: AgentSchema | null) => void): void {
  const c = formRef.current
  const t = type === 'applicant' ? '申请人' : '被申请人'
  const l = type === 'applicant' ? caseDetail.applicant_agents : caseDetail.respondents_agents
  if (c === null) {
    return undefined
  }
  if (id === undefined) {
    c.setFieldsValue({ party_type: t })
  }
  for (let i = 0; i < l.length; i++) {
    if (l[i].id === id) {
      formatData(l[i])
      c.setFieldsValue(l[i])
      setAgent(l[i])
      break
    }
  }
}

// 格式化数据标签
function formatData (d: AgentSchema): void {
  if (d.file instanceof Array && d.file.length > 0) {
    d.front = d.file[0]
  }
  if (d.file1 instanceof Array && d.file1.length > 0) {
    d.back = d.file1[0]
  }
}

async function delHandle (p: AgentSchema, c: CaseSchema, history: History, refreshCase: () => {}): Promise<void> {
  const hide = message.loading('删除中', 120)
  const [e] = await awaitWrap(delAgentRequest(p.id === undefined ? '' : p.id))
  hide()
  Modal.destroyAll()
  if (e !== null) {
    await undefined
  } else {
    refreshCase()
    history.push(`/admin/case-edit/${c.id}`)
    await message.success('删除成功')
  }
}

// 删除当事人
function confrimDel (p: AgentSchema, c: CaseSchema, history: History, refreshCase: () => {}): void {
  confirm({
    icon: <ExclamationCircleOutlined />,
    content: `您确定要删除 ${p.name} 吗？`,
    onOk: () => {
      delHandle(p, c, history, refreshCase).catch(e => console.error(e))
    }
  })
}

// 提交
async function submitHandle (p: AgentSchema, origin: AgentSchema | null, history: History, params: Params, refreshCase: () => {}): Promise<void> {
  const hide = message.loading('提交中', 120)
  if (p.id !== undefined && origin !== null) {
    p = { ..._.cloneDeep(origin), ...p }
  } else {
    p.caseid = params.caseid
  }
  p.party.forEach(l => {
    delete l.upload
    delete l.file
  })
  const [e] = await awaitWrap(agentRequest(_.cloneDeep(p)))
  hide()
  if (e === null) {
    history.push(`/admin/case-edit/${params.caseid}`)
    refreshCase()
    await message.success(p.id !== undefined ? '更新成功' : '新增成功')
  }
}

function Main ({ history, match, caseDetail, dictionary, userInfo, refreshCase }: Props): ReactElement {
  const formRef = useRef(null)
  const [agent, setAgent] = useState<AgentSchema | null>(null)
  const [type, setType] = useState('applicant')
  const [typeLabel, setTypeLabel] = useState('申请人')
  const [isSelf, setIsSelf] = useState(false)
  const [identityTypeList, setIdentityTypeList] = useState<DictionaryItem[]>([])
  const [roleList, setRoleList] = useState<DictionaryItem[]>([])
  useEffect(() => {
    if (match !== null) {
      const { type, id } = match.params
      setTypeLabel(type === 'applicant' ? '申请人' : '被申请人')
      setType(type)
      initIsSelf(caseDetail, setIsSelf, userInfo.id, id)
    }
  }, [match])
  useEffect(() => {
    if (match !== null && caseDetail !== null) {
      const { type, id } = match.params
      initData(caseDetail, type, id, formRef, setAgent)
    }
  }, [match, caseDetail])
  useEffect(() => {
    setIdentityTypeList(dictionary.getList('自然人证件类型'))
    setRoleList(dictionary.getList('代理人角色'))
  }, [dictionary])
  return (
    <div className={style.container}>
      <div className={style.header}>
        <span className={style['header-icon']} onClick={() => history.goBack()}>
          <img src={backIcon} />
        </span>
        <span className={style['header-title']}>{typeLabel}代理人</span>
      </div>
      <Form ref={formRef} className={style.form} labelCol={{ flex: '138px' }} labelAlign='left' colon={false} initialValues={{ party: [] }} onFinish={async d => await submitHandle(d, agent, history, match.params, refreshCase)} scrollToFirstError={scrollToFirstError}>
        <Item name='id' noStyle><span /></Item>
        <Item name='party_type' noStyle><span /></Item>
        <Item name='party' label='代理对象' rules={[{ required: true, message: '代理对象不能为空' }]}>
          <PartyList type={type} caseDetail={caseDetail} dictionary={dictionary} />
        </Item>
        <Item label='姓名' name='name' rules={[{ required: true, message: '代理人姓名不能为空' }]}>
          <Input className={style.long} placeholder='请输入代理人姓名' disabled={isSelf} />
        </Item>
        <Item label='证件类型' name='license_type' rules={[{ required: true, message: '证件类型不能为空' }]}>
          <Select className={style.long} placeholder='请选择证件类型' disabled={isSelf}>
            {identityTypeList.map(li => <Option key={li.title} value={li.title}>{li.title}</Option>)}
          </Select>
        </Item>
        <Item label='证件号码' name='license' rules={[{ required: true, message: '证件号码不能为空' }]}>
          <Input className={style.long} placeholder='请输入证件号码' disabled={isSelf} />
        </Item>
        <Item label='证件照' style={{ marginBottom: 0 }}>
          <Space size={24}>
            <Item name='front' style={{ display: 'inline-block' }} rules={[{ required: false, message: '人相照不能为空' }]}>
              <IdentityCard label='人相照' isFront disabled={isSelf} />
            </Item>
            <Item name='back' style={{ display: 'inline-block' }} rules={[{ required: false, message: '国徽照不能为空' }]}>
              <IdentityCard label='国徽照' isFront={false} disabled={isSelf} />
            </Item>
          </Space>
        </Item>
        <Item label='所在单位' name='structure' rules={[{ required: true, message: '所在单位不能为空' }]}>
          <Input className={style.long} placeholder='请输入所在单位' />
        </Item>
        <Item label='地址' name='address' rules={[{ required: true, message: '地址不能为空' }]}>
          <TextArea className={style.long} placeholder='请输入地址' />
        </Item>
        <Item label='代理人角色' name='role' rules={[{ required: true, message: '代理人角色不能为空' }]}>
          <Select className={style.long} placeholder='请选择证件类型'>
            {roleList.map(li => <Option key={li.title} value={li.title}>{li.title}</Option>)}
          </Select>
        </Item>
        <Item label='手机号码' name='mobile' rules={[{ required: true, message: '手机号不能为空' }, { pattern: /^1\d{10}$/, message: '手机号码格式不正确' }]}>
          <Input className={style.long} placeholder='手机号自动关联账号，请使用自己的手机号' maxLength={11} />
        </Item>
        <Item label='邮箱' name='email' rules={[{ type: 'email', message: '邮箱格式不正确' }]}>
          <Input className={style.long} placeholder='请输入邮箱' />
        </Item>
        <Item label='固定电话' name='tel'>
          <Input className={style.long} placeholder='请输入固定电话' />
        </Item>
        <div className={style['btn-line']}>
          <Space size='middle'>
            {agent?.id !== undefined ? <Button disabled={isSelf} onClick={async () => confrimDel(agent, caseDetail, history, refreshCase)}>删除</Button> : null}
            <Button type='primary' htmlType='submit'>确认</Button>
          </Space>
        </div>
      </Form>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
