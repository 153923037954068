import { EffectiveActionSchema } from '@src/components/Schema/EffectiveSchema'
import { ReactElement, useMemo } from 'react'
import style from './Item.module.less'
import { formatDate } from '@src/assets/js/tool'
import FileList from '@components/FileList'

function filterEmpty (v: any): any {
  if (v === null || v === '' || v === undefined) {
    return '无'
  }
  return v
}

interface Props {
  data: EffectiveActionSchema
  index: number
}

function Main ({ data, index }: Props): ReactElement {
  const isProcess = useMemo(() => {
    return data.type === 'process'
  }, [data])
  return (
    <div className={style.container}>
      <div className={style.order}>{index}</div>
      <div>
        <div className={style.line}>
          <div className={style.label}>{isProcess ? '审批人' : '申请人'}</div>
          <div className={style.value}>
            <span>{data.operator}</span>
            <span className={style.date}>{formatDate(data.create_datetime, 'YYYY-MM-DD HH:mm')}</span>
          </div>
        </div>
        <div className={style.line}>
          <div className={style.label}>{isProcess ? '反馈内容' : '申请内容'}</div>
          <div className={style.value}>{filterEmpty(data.content)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>附件</div>
          <div className={style.value}>
            <FileList value={data.file} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
