import React, { useEffect, useMemo, useState } from 'react'
import style from './Index.module.less'
import { Button, Col, Form, Input, Row, Space, message } from 'antd'
import Text from '@components/Text/Index'
import { useHistory } from 'react-router-dom'
import { awaitWrap, decompress } from '@js/tool'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import SendSmsValidateModal from '@components/SendSmsValidateModal/Index'
import { loginRequest } from './ajax'
import { setToken, setUserInfo } from '@redux/actions/basic'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { History } from 'history'

const { Item, useForm } = Form

async function login ({ username, password, code }: StateSchema, dispatch: Dispatch<any>, history: History): Promise<void> {
  const h = message.loading('提交中', 120)
  const [e, d] = await awaitWrap(loginRequest(username, password, code))
  h()
  if (e === null && d !== null) {
    const { userInfo, token } = d
    if (userInfo !== null && typeof userInfo.mobile === 'string') {
      userInfo.mobile = decompress(userInfo.mobile)
    }
    dispatch(setUserInfo(userInfo))
    dispatch(setToken(token))
    history.replace('/')
  }
}

function loop (sendTime: number, setLoopTime: (v: number) => void): void {
  if (typeof sendTime !== 'number' && sendTime > 0) {
    return undefined
  }
  const n = Date.now()
  const t = Math.floor((n - sendTime) / 1000)
  if (t <= 60) {
    setLoopTime(60 - t)
    setTimeout(() => {
      loop(sendTime, setLoopTime)
    }, 1000)
  }
}

interface StateSchema { username: string, password: string, mobile: string, code: string }

function Main (): React.ReactElement {
  const h = useHistory()
  const dispatch = useDispatch()
  const [formRef] = useForm()
  const [loopTime, setLoopTime] = useState(0)
  const sendTime = useSelector((state: { basic: BASIC_STATE }) => state.basic.SMSSendTime)
  const [visible, setVisible] = useState(false)
  const [mobile, setMobile] = useState('')
  const disable = useMemo(() => {
    return loopTime > 0
  }, [loopTime])
  useEffect(() => {
    const s: StateSchema = h.location.state as StateSchema
    if (s !== null && formRef !== null) {
      const o: {[k: string]: any} = { username: s.username, password: s.password }
      if (typeof s.mobile === 'string') {
        const m = decompress(s.mobile)
        setMobile(m)
        o.mobile = m
      }
      formRef.setFieldsValue(o)
    }
  }, [h, formRef])
  useEffect(() => {
    if (sendTime !== 0) {
      loop(sendTime, setLoopTime)
    }
  }, [sendTime])
  return (
    <div className={style.container}>
      <SendSmsValidateModal visible={visible} hide={() => setVisible(false)} mobile={mobile} callback={() => { setVisible(false) }} />
      <Form
        form={formRef} labelCol={{ flex: '80px' }} onFinish={(d) => {
          login(d, dispatch, h).then(null, null)
        }}
      >
        <Item name='username' noStyle><span /></Item>
        <Item name='password' noStyle><span /></Item>
        <Item label='手机号' name='mobile'>
          <Text format={(v: string) => {
            if (typeof v === 'string' && v !== '') {
              return `${v.slice(0, 3)}****${v.slice(7)}`
            }
            return v
          }}
          />
        </Item>
        <Row gutter={24}>
          <Col span={18}>
            <Item label='验证码' name='code' rules={[{ required: true, message: '验证码不能为空' }, { len: 4, message: '验证码格式不正确' }]}>
              <Input placeholder='请输入' maxLength={4} />
            </Item>
          </Col>
          <Col span={6}>
            <Button disabled={disable} onClick={() => { setVisible(true) }}>{loopTime > 0 ? `${loopTime}秒` : '发送验证码'}</Button>
          </Col>
        </Row>
        <Item label=' ' name='operate' colon={false}>
          <Space>
            <Button type='primary' htmlType='submit'>提交</Button>
          </Space>
        </Item>
      </Form>
    </div>
  )
}

export default Main
