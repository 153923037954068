import { FaPiaoSchema } from '@src/components/Schema/FaPiaoSchema'
import { Button, Modal } from 'antd'
import { ReactElement, useMemo } from 'react'
import Item from './Item'
import First from './First'

interface Props {
  data: FaPiaoSchema | null
  visible: boolean
  hide: () => void
}

function Main ({ data, visible, hide }: Props): ReactElement {
  const list = useMemo(() => {
    return data !== null && data.actions instanceof Array ? data.actions : []
  }, [data])
  return (
    <Modal title='详情' visible={visible} onCancel={hide} footer={<Button type='primary' onClick={hide}>确定</Button>}>
      {data !== null && <First data={data} />}
      {
        list.map((li, i) => {
          return <Item data={li} key={i} index={i + 2} />
        })
      }
    </Modal>
  )
}

export default Main
