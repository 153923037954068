import { requestOneFile, $put, $post, $delete } from '@js/request'
import { compress } from '@src/assets/js/tool'
import { AgentSchema } from '@src/components/Schema/AgentSchema'

// 更新或新增当事人数据
export async function agentRequest (p: AgentSchema): Promise<void> {
  if (p.front instanceof File) {
    const res = await requestOneFile(p.front)
    p.fileid = res.id
  }
  if (p.back instanceof File) {
    const res = await requestOneFile(p.back)
    p.fileid1 = res.id
  }
  const id = p.id
  delete p.id
  delete p.front
  delete p.back
  delete p.file
  delete p.file1
  if (typeof p.license === 'string' && p.license !== '') {
    p.license = compress(p.license)
  }
  if (typeof p.mobile === 'string' && p.mobile !== '') {
    p.mobile = compress(p.mobile)
  }
  let res
  if (id === undefined) {
    res = await $post('/api/xd/agent', p) // 新增数据
  } else {
    res = await $put(`/api/xd/agent/${id}`, p) // 更新数据
  }
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 删除代理人
export async function delAgentRequest (id: string): Promise<void> {
  const res = await $delete(`/api/xd/agent/${id}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
