import { $get, $post } from '@js/request'
import { AccountSchema } from '@components/Schema/AccountSchema'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { compress, decompress, formatCaseDataLicenseAndMobile } from '@src/assets/js/tool'

// 获取登录者信息
export async function getAccountInfoRequest (): Promise<AccountSchema> {
  const res = await $get('/api/xd/certify/getByAccountid')
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  const o = res.data
  if (o !== null) {
    if (typeof o.license === 'string' && o?.license !== '') {
      res.data.license = decompress(res.data.license)
    }
    if (typeof o.mobile === 'string' && o?.mobile !== '') {
      res.data.mobile = decompress(res.data.mobile)
    }
  }
  return res.data
}

// 创建新案件
export async function createCaseRequest (source: string): Promise<string> {
  const d: { [k: string]: any } = { }
  if (typeof source === 'string' && source !== '') {
    d.source = source
  }
  const res = await $post('/api/xd/case', d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 新增当事人
export async function addPartyRequest (d: {[k: string]: any}): Promise<void> {
  if (d !== null) {
    if (typeof d.mobile === 'string' && d.mobile === '') {
      d.mobile = compress(d.mobile)
    }
    if (typeof d.license === 'string' && d.license === '') {
      d.license = compress(d.license)
    }
  }
  const res = await $post('/api/xd/party', d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 新增代理人
export async function addAgentRequest (d: {[k: string]: any}): Promise<void> {
  if (d !== null) {
    if (typeof d.mobile === 'string' && d.mobile === '') {
      d.mobile = compress(d.mobile)
    }
    if (typeof d.license === 'string' && d.license === '') {
      d.license = compress(d.license)
    }
  }
  const res = await $post('/api/xd/agent', d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 获取案件基本信息
export async function getCaseRequest (caseid: string): Promise<CaseSchema> {
  const res = await $get(`/api/xd/case/${caseid}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  if (res.data !== null) {
    formatCaseDataLicenseAndMobile(res.data)
  }
  return res.data
}
