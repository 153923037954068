import React, { ReactElement, useEffect, useState } from 'react'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { getListRequest } from './ajax'
import { EffectiveSchema } from '@src/components/Schema/EffectiveSchema'
import { awaitWrap } from '@src/assets/js/tool'
import { Button } from 'antd'
import List from './components/Item/Index'
import { useHistory } from 'react-router-dom'
import HistoryModal from './components/HistoryModal/Index'

interface Props {
  data: CaseSchema
}

// 获取列表
async function initList (caseid: string, setList: (v: EffectiveSchema[]) => void): Promise<void> {
  const [e, d] = await awaitWrap(getListRequest(caseid))
  if (e === null && d !== null) {
    setList(d)
  }
}

function Main ({ data }: Props): ReactElement {
  const h = useHistory()
  const [list, setList] = useState<EffectiveSchema[]>([])
  const [visible, setVisible] = useState(false)
  const [detail, setDetail] = useState<EffectiveSchema | null>(null)
  useEffect(() => {
    if (data !== null) {
      initList(data.id, setList).catch(e => console.log(e))
    }
  }, [data])
  return (
    <div id='party_shengxiaozhengming'>
      <HistoryModal visible={visible} hide={() => { setVisible(false) }} data={detail} />
      <div className={style.header}>开具生效证明</div>
      {
        list.map((li, i) => {
          return <List caseid={data.id} data={li} key={li.id} index={i} setDetail={setDetail} setVisible={setVisible} />
        })
      }
      <div className={style['btn-line']}>
        <Button onClick={() => h.push(`/admin/case-detail/${data.id}/effectiveness`)}>
          {list.length > 0 ? '再申请开具生效证明' : '申请开具生效证明'}
        </Button>
      </div>
    </div>
  )
}

export default Main
