import { ReactElement, useState, useRef, useEffect } from 'react'
import { Modal, Form, Input, Row, Col, Button, FormInstance, message, Alert } from 'antd'
import { modifyRequest } from './ajax'
import { awaitWrap, compress } from '@src/assets/js/tool'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import style from './Index.module.less'
import { setSMSSendTime, setToken, setUserInfo } from '@src/redux/actions/basic'
import { useHistory } from 'react-router-dom'
import { History } from 'history'
import SendSmsValidateModal from '@components/SendSmsValidateModal/Index'

const { Item } = Form

async function validator (rule: any, value: string): Promise<any> {
  if (value === '' || value === undefined || value === null) {
    return await Promise.resolve()
  }
  if (value.length < 8) {
    return await Promise.reject(new Error('最小长度为8位'))
  }
  let count = 0
  if (/[$-]+/.test(value)) {
    return await Promise.reject(new Error('新密码不能包含-和$'))
  }
  if (/[a-z]+/.test(value) && /[A-Z]+/.test(value)) {
    count++
  }
  if (/[~!@#%^&*()_+=*/<>?/\\.,;'"]+/.test(value)) {
    count++
  }
  if (/[0-9]+/.test(value)) {
    count++
  }
  if (count < 3) {
    return await Promise.reject(new Error('新密码必须包含大小写字母+数字+特殊符号'))
  }
  return await Promise.resolve()
}

function initLoop (sendTime: number, setLoopeTime: (v: number) => void): void {
  const t = Math.floor((Date.now() - sendTime) / 1000)
  if (t < 60) {
    setLoopeTime(60 - t)
    setTimeout(() => {
      initLoop(sendTime, setLoopeTime)
    }, 1000)
  } else {
    setLoopeTime(0)
  }
}

async function moidfyHandle (data: {[k: string]: any}, history: History, dispatch: Dispatch): Promise<void> {
  const h = message.loading('提交中', 120)
  if (typeof data.mobile === 'string' && data.mobile !== '') {
    data.mobile = compress(data.mobile)
  }
  const [e] = await awaitWrap(modifyRequest(data))
  h()
  if (e === null) {
    message.success('修改成功，请重新登录').then(null, null)
    dispatch(setToken(''))
    dispatch(setUserInfo({
      app_default: '',
      desktop: '',
      employeeid: '',
      id: '',
      is_auth: -1,
      mobile: '',
      realname: '',
      role_default: '',
      is_expire: 0,
      expire_info: ''
    }))
    history.push('/login')
  }
}

interface Props {
  visible: boolean
  hide: () => void
  expireInfo: string
  isExpire: boolean
}

function Main ({ visible, hide, expireInfo, isExpire }: Props): ReactElement {
  const [loopTime, setLoopeTime] = useState(0)
  const [passwordHidable, setPasswrodHidable] = useState(true)
  const [smsValidateVisible, setSmsValidateVisible] = useState(false)
  const formRef = useRef<FormInstance>(null)
  const dispatch = useDispatch()
  const { userInfo, sendTime } = useSelector((state: { basic: BASIC_STATE }) => {
    return {
      userInfo: state.basic.userInfo,
      sendTime: state.basic.SMSSendTime
    }
  })
  useEffect(() => {
    initLoop(sendTime, setLoopeTime)
  }, [sendTime])
  const h = useHistory()
  return (
    <Modal
      title='修改密码'
      visible={visible}
      onOk={() => formRef.current?.submit()}
      onCancel={() => hide()}
      maskClosable={false}
      destroyOnClose
      closable={!isExpire}
    >
      <SendSmsValidateModal
        visible={smsValidateVisible}
        hide={() => {
          setSmsValidateVisible(false)
        }}
        mobile={userInfo.mobile}
        callback={() => { setSmsValidateVisible(false); setTimeout(() => { dispatch(setSMSSendTime(Date.now())) }, 120) }}
      />
      <Form name='new-password' labelCol={{ flex: '88px' }} ref={formRef} onFinish={async (d: {[k: string]: any}) => {
        d.mobile = userInfo.mobile
        await moidfyHandle(d, h, dispatch)
      }}>
        {expireInfo !== '' && <Alert message={expireInfo} type='warning' style={{ marginBottom: '12px' }} />}
        <Item label='手机号'>
          <span>{userInfo.mobile}</span>
        </Item>
        <Item name='new_password' label='新密码' rules={[{ required: true, message: '新密码不能为空' }, { validator }]}>
          <Input type={passwordHidable ? 'password' : 'text'} placeholder='请输入新密码' suffix={(
            <span className={style['eye-btn']} onClick={() => { setPasswrodHidable(!passwordHidable) }}>
              {passwordHidable ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            </span>
          )} allowClear />
        </Item>
        <Row>
          <Col flex={1}>
            <Item name='verification' label='验证码' rules={[{ required: true, message: '验证码不能为空' }]}>
              <Input maxLength={4} placeholder='请输入验证码' allowClear />
            </Item>
          </Col>
          <Col offset={1}>
            {
             loopTime !== 0
               ? (
              <Button disabled>{loopTime}秒</Button>
                 )
               : (
              <Button type='primary' onClick={() => { setSmsValidateVisible(true) }} ghost>发送验证码</Button>
                 )}
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default Main
