import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { Checkbox } from 'antd'
import _ from 'lodash'
import style from './Index.module.less'

interface BlockProps {
  options: string[]
  value: string[]
  name: string
  detail: boolean
  onChange: (v: string[]) => void
}

// 改变选中项
function toggleValues (checked: boolean, options: string[], value: string[], onChange: (v: string[]) => void): void {
  const pureList = value.filter(li => !options.includes(li))
  if (checked) {
    onChange(pureList)
  } else {
    onChange(_.concat([], pureList, options))
  }
}

// 切换选中状态
function toggleChecked (value: string[], name: string, onChange: (v: string[]) => void): void {
  const list = [...value]
  const index = list.indexOf(name)
  if (index !== -1) {
    list.splice(index, 1)
  } else {
    list.push(name)
  }
  onChange(list)
}

function Block ({ options = [], value, name, detail, onChange }: BlockProps): ReactElement {
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  useEffect(() => {
    const every = options.every(li => value.includes(li))
    const some = options.some(li => value.includes(li))
    setChecked(every)
    setIndeterminate(some && !every)
  }, [value])
  if (!detail) {
    return <Checkbox checked={checked} indeterminate={indeterminate} onChange={() => toggleValues(checked, options, value, onChange)}>{name}</Checkbox>
  }
  return (
    <>
      {
        options.map(li => {
          return <Checkbox key={li} value={li} checked={checked || value.includes(li)} onChange={() => toggleChecked(value, li, onChange)}>{li}</Checkbox>
        })
      }
    </>
  )
}

export interface AreaOption {
  [k: string]: string[]
}

interface Props {
  options: AreaOption
  value?: string[]
  onChange?: (v: string[]) => void
}

function Main ({ options, value = [], onChange = () => {} }: Props): ReactElement {
  const [detail, setDetail] = useState(false)
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const totalKeys = useMemo(() => {
    return options['全部'] instanceof Array ? options['全部'] : []
  }, [options])
  const allOptions = useMemo(() => {
    return _.concat([], ...totalKeys.map(key => options[key] instanceof Array ? options[key] : []))
  }, [options, totalKeys])
  useEffect(() => {
    const every = allOptions.every(li => value.includes(li))
    const some = allOptions.some(li => value.includes(li))
    setChecked(every)
    setIndeterminate(some && !every)
  }, [value, allOptions])
  return (
    <div className={style.container}>
      <Checkbox checked={checked} indeterminate={indeterminate} onClick={() => onChange(checked ? [] : allOptions)}>全选</Checkbox>
      {
        totalKeys.map((key, i) => {
          return (
            <Block options={options[key]} key={i} value={value} name={key} detail={key !== '本地' ? detail : false} onChange={onChange} />
          )
        })
      }
      <a className={style.btn} onClick={() => setDetail(!detail)}>{detail ? '收起' : '更多'}</a>
    </div>
  )
}

export default Main
