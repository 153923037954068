import { Modal, message } from 'antd'
import React, { ReactElement, useEffect, useRef } from 'react'
import style from './Index.module.less'
import { awaitWrap } from '@js/tool'
import { sendSMS } from './ajax'
import { useDispatch } from 'react-redux'
import { setSMSSendTime } from '@redux/actions/basic'

async function sendHandle (mobile: string, arr: number[]): Promise<boolean> {
  const h = message.loading('校验中', 120)
  const [e] = await awaitWrap(sendSMS(mobile, arr))
  h()
  if (e !== null) {
    message.error(e.message).then(null, null)
  }
  return e === null
}

interface Props {
  visible: boolean
  hide: () => void
  mobile: string
  callback: () => void
}

function Main ({ visible, hide, mobile, callback }: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  useEffect(() => {
    if (visible && ref.current !== null) {
      const e = document.createElement('div')
      e.setAttribute('id', 'captcha')
      ref.current.appendChild(e)
      sliderCaptcha({
        id: 'captcha',
        width: 280,
        height: 150,
        sliderL: 42,
        sliderR: 12,
        offset: 8,
        loadingText: '正在加载中...',
        failedText: '再试一次',
        barText: '向右滑动填充拼图',
        repeatIcon: 'fa fa-redo',
        remoteUrl: '/api/common/smsCaptcha',
        setSrc: function () {
          return `/slidercaptcha/images/Pic${Math.floor(Math.random() * 4)}.jpg`
        },
        verify: async function (arr: number[], spliced: boolean) {
          if (!spliced) {
            throw new Error('验证失败')
          }
          const res = await sendHandle(mobile, arr)
          if (res) {
            message.success('短信发送成功').then(null, null)
            callback()
            dispatch(setSMSSendTime(Date.now()))
          } else {
            throw new Error('验证失败')
          }
        }
      })
      return () => {
        if (ref.current !== null) {
          ref.current.removeChild(e)
        }
      }
    }
  }, [visible, ref, mobile, callback, dispatch])
  return (
    <Modal title='安全验证' visible={visible} onCancel={hide} footer={null} forceRender>
      <div ref={ref} className={style.container} />
    </Modal>
  )
}

export default Main
