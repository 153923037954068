import { $freePost, $get } from '@js/request'
import { USERINFO } from '@redux/actions/basic'
import { compress } from '@src/assets/js/tool'

interface LoginRes {
  token: string
  userInfo: USERINFO
  verify_plus: string[]
  mobile: string
}

function trim (v: string): string {
  if (typeof v === 'string') {
    return v.trim()
  }
  return v
}

// 发送短信请求
export async function sendSMSRequest (mobile: string): Promise<string> {
  const res = await $freePost('/api/common/sms', {
    mobile: compress(trim(mobile)),
    template_code: 'SMS_182920835'
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 通过手机号和验证码登录
export async function loginByMobileRequest (zoneNum: string, mobile: string, verification: string): Promise<LoginRes> {
  const res = await $freePost('/api/common/mobile_login', {
    zoneNum: trim(zoneNum),
    mobile: compress(trim(mobile)),
    verification: trim(verification)
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 通过账号密码登录
export async function loginByUsernameRequest (username: string, password: string): Promise<LoginRes> {
  const res = await $freePost('/api/common/vuser_login', {
    username: trim(username),
    password: compress(trim(password))
  })
  const d = res.data as LoginRes
  if (res.status !== 1) {
    if (d.verify_plus instanceof Array && d.verify_plus.length > 0) {
      return res.data
    }
    throw new Error(res.message)
  }
  return res.data
}

// 获取案件列表
export async function getCaseListRequest (): Promise<number> {
  const res = await $get('/api/xd/case/findCaseByAccountid', {
    params: {
      current: 1,
      page_size: 1000
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data.total
}

export async function getVersionRequest (): Promise<{ version: string}> {
  const res = await $get('/api/common/bizconfig/getSystemVersion', {
    params: {
      system_name: '当事人系统'
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
