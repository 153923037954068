import React, { ReactElement, useState, useEffect, useMemo } from 'react'
import { Form, Select, Input, Space, Button, message, InputNumber } from 'antd'
import { History } from 'history'
import { useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO } from '@redux/actions/basic'
import { getPartyListByAccountId, User } from './tool'
import Upload, { uploadFile } from '@components/Upload/Index'
import { createEffective, postAgainRequest } from './ajax'
import { awaitWrap, compress, scrollToAnchor } from '@js/tool'
import Text from '@components/Text/Index'
import { FormInstance } from 'antd/es/form/Form'
import { PartySchema } from '@src/components/Schema/PartySchema'
import Dictionary, { DictionaryItem } from '@src/assets/js/dictionary'

const { Item, useForm } = Form
const { Option } = Select
const { TextArea } = Input

interface Params {
  caseid: string
  id: string
}

interface Props {
  caseDetail: CaseSchema
  userInfo: USERINFO
  refreshCase: () => void
  refreshOpereate: (k: string) => void
}

// 提交事件
async function submitHandle (d: {[k: string]: any}, caseid: string, id: string, type: string, userList: User[], history: History, refreshCase: () => void, refreshOpereate: (v: string) => void): Promise<void> {
  let fn = createEffective
  if (id !== '' && typeof id === 'string') {
    fn = postAgainRequest
  }
  if (d.file instanceof Array) {
    const [e, l] = await awaitWrap(uploadFile(d.file))
    if (e !== null) {
      return undefined
    } else if (l === null) {
      return await message.error('文件id未返回')
    } else {
      d.fileid = l.map(li => li.id).join()
    }
  }
  delete d.file
  if (typeof id === 'string' && id !== '') {
    d.id = id
  } else {
    d.caseid = caseid
  }
  d.applicant_license = compress(d.applicant_license)
  d.applicant_mobile = compress(d.applicant_mobile)
  const [e] = await awaitWrap(fn(d))
  if (e !== null) {
    return undefined
  }
  history.push(`/admin/case-detail/${caseid}`)
  refreshOpereate('party_tuifei')
  refreshCase()
  setTimeout(() => {
    scrollToAnchor('#party_tuifei', history)
  }, 500)
}

function cancelToBack (id: string, h: History): void {
  h.push(`/admin/case-detail/${id}`)
  setTimeout(() => {
    scrollToAnchor('#party_tuifei', h)
  }, 1000)
}

function computeFee (formRef: FormInstance): void {
  const c = formRef.getFieldsValue()
  let f = 0
  if (typeof c.exvars_fee_acceptance === 'number') {
    f += c.exvars_fee_acceptance as number
  }
  if (typeof c.exvars_fee_processing === 'number') {
    f += c.exvars_fee_processing as number
  }
  if (typeof c.exvars_fee_other === 'number') {
    f += c.exvars_fee_other as number
  }
  formRef.setFieldsValue({
    exvars_fee: f
  })
}

function changeApplicant (id: string, type: string, caseDetail: CaseSchema, formRef: FormInstance, setSubjectType: (v: string) => void): void {
  let list: PartySchema[] = []
  if (type === '申请人' || type === '申请人代理人') {
    list = caseDetail.applicants
  } else if (type === '被申请人' || type === '被申请人代理人') {
    list = caseDetail.respondents
  }
  const o = list.find(li => li.id === id)
  if (o !== null) {
    setSubjectType(o?.subject_type as string)
    formRef.setFieldsValue({
      applicant_license: o?.license,
      applicant_license_type: o?.license_type,
      applicant_mobile: o?.mobile,
      applicant: o?.name
    })
  }
}

function Main ({ caseDetail, userInfo, refreshCase, refreshOpereate }: Props): ReactElement {
  const history = useHistory()
  const params = useParams<Params>()
  const [formRef] = useForm()
  const [userList, setUserList] = useState<User[]>([])
  const [type, setType] = useState('')
  const [subjectType, setSubjectType] = useState('自然人')
  const [dictionary, setDictionary] = useState<Dictionary | null>(null)
  const id = useMemo(() => {
    return params.id
  }, [params])
  const caseid = useMemo(() => {
    return params.caseid
  }, [params])
  const licenseType = useMemo<DictionaryItem[]>(() => {
    if (dictionary !== null) {
      if (subjectType === '自然人') {
        return dictionary.getList('自然人证件类型')
      }
      if (subjectType === '企业') {
        return dictionary.getList('企业证件类型')
      }
    }
    return []
  }, [subjectType, dictionary])
  useEffect(() => {
    const d = new Dictionary()
    d.init('主体类型,企业证件类型,自然人证件类型')
      .then(() => {
        setDictionary(d)
      })
      .catch(e => console.error(e))
  }, [])
  useEffect(() => {
    const o: { type: string, list: User[] } = getPartyListByAccountId(caseDetail, userInfo.id)
    setUserList(o.list)
    setType(o.type)
  }, [caseDetail, userInfo])
  useEffect(() => {
    if (formRef !== null) {
      const o: {[k: string]: any} = { }
      if (typeof type === 'string' && type !== '') {
        o.applicant_type = type
      }
      formRef.setFieldsValue(o)
    }
  }, [type, formRef])
  return (
    <div>
      <div className={style.header}>退费</div>
      <div className={style.block}>
        <Form form={formRef} labelCol={{ flex: '100px' }} onFinish={async d => await submitHandle(d, caseid, id, type, userList, history, refreshCase, refreshOpereate)}>
          <Item label='申请人类型' name='applicant_type'>
            <Text />
          </Item>
          <Item label='申请人' name='applicantid' rules={[{ required: true, message: '申请人不能为空' }]}>
            <Select placeholder='请选择申请人' onChange={(v) => changeApplicant(v as string, type, caseDetail, formRef, setSubjectType)}>
              {userList.map(li => {
                return <Option key={li.id} value={li.id}>{li.name}</Option>
              })}
            </Select>
          </Item>
          <Item name='applicant' noStyle><span /></Item>
          <Item label='手机号' name='applicant_mobile' rules={[{ required: true, message: '手机号不能为空' }]}>
            <Input placeholder='请输入' allowClear />
          </Item>
          <Item label='证件类型' name='applicant_license_type' rules={[{ required: true, message: '证件类型不能为空' }]}>
            <Select placeholder='请选择'>
              {licenseType.map((li, i) => {
                return (
                  <Option value={li.title} key={i}>{li.title}</Option>
                )
              })}
            </Select>
          </Item>
          <Item label='证件号' name='applicant_license' rules={[{ required: true, message: '证件号不能为空' }]}>
            <Input placeholder='请输入' allowClear />
          </Item>
          <Item label='退费类型' name='subtype' rules={[{ required: true, message: '退费类型不能为空' }]}>
            <Select placeholder='请选择' allowClear>
              <Option value='仲裁费'>仲裁费</Option>
              <Option value='外地仲裁员办案费'>外地仲裁员办案费</Option>
              <Option value='鉴定费'>鉴定费</Option>
              <Option value='其他'>其他</Option>
            </Select>
          </Item>
          <Item label='退受理费' name='exvars_fee_acceptance'>
            <InputNumber placeholder='请输入' min={0} className={style.full} precision={2} onChange={v => computeFee(formRef)} />
          </Item>
          <Item label='退处理费' name='exvars_fee_processing'>
            <InputNumber placeholder='请输入' min={0} className={style.full} precision={2} onChange={v => computeFee(formRef)} />
          </Item>
          <Item label='退其他费' name='exvars_fee_other'>
            <InputNumber placeholder='请输入' min={0} className={style.full} precision={2} onChange={v => computeFee(formRef)} />
          </Item>
          <Item label='退费总费' name='exvars_fee'>
            <InputNumber placeholder='请输入' min={0} className={style.full} precision={2} readOnly />
          </Item>
          <Item label='申请内容' name='content'>
            <TextArea autoSize={{ minRows: 5 }} placeholder='请输入申请内容' />
          </Item>
          <Item label='附件材料' name='file'>
            <Upload accept='.jpg,.jpeg,.png,.pdf,.doc,.docx,.xlsx,.xls' />
          </Item>
          <div className={style['btn-line']}>
            <Space>
              <Button htmlType='button' onClick={() => cancelToBack(params.caseid, history)}>取消</Button>
              <Button type='primary' htmlType='submit'>确认提交</Button>
            </Space>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {}
})(Main)
