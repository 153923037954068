export const companyName = '厦门仲裁委员会'
export const appName = '云上厦仲'
export const companyUrl = 'www.xmac.org.cn'

export const clanList = ['汉族', '壮族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '东乡族', '锡伯族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '僳僳族', '佤族', '畲族', '拉祜族', '水族', '纳西族', '景颇族', '柯尔克孜族', '土族', '高山族', '达斡尔族', '仫佬族', '羌族', '撒拉族', '德昂族', '仡佬族', '阿昌族', '普米族', '布朗族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '毛南族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族']

export const pk = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1IktOwr+4VSXGUmYfaqsfe1dT
VPKOW/LYQUHF7VBB75Pa5gMl+wI9CW2Y7th9F/9MFokQINzoZg1bsR157XOdO8bE
Vr00HBpFCtyHxw9jqWoaivDJlxsmYXdC24X46mWg8/E6LWGOXx98cTQ3k/K67NwD
r6JaRBJp1tkVcO4pkwIDAQAB
-----END PUBLIC KEY-----`
