import { $get } from '@js/request'
// 当事人对象
export interface Info {
  address: string
  area: string
  auth_key: string
  businessstatus: string
  certifyid: string
  city: string
  contact: string
  email: string
  legal: string
  legal_license: string
  legal_license_type: string
  license: string
  license_type: string
  mobile: string
  name: string
  province: string
  tel: string
  tag: string
  subject_type_tag: string
  id?: string
}

// 获取匹配数据
export async function getPersonListRequest (name: string): Promise<Info[]> {
  const res = await $get('/api/xd/party/getByName', {
    name,
    subject_type: '企业'
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 通过完整姓名获取数据
export async function getPersonDataRequest (name: string): Promise<Info> {
  const res = await $get('/api/xd/party/getByFullName', {
    name,
    subject_type: '企业'
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  if (res.data.length === 0) {
    throw new Error('数据不存在')
  }
  return res.data[0]
}
