import React, { ReactElement, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Space, Dropdown, Avatar, Menu, message } from 'antd'
import { History, Location } from 'history'
import { UserOutlined, PoweroffOutlined } from '@ant-design/icons'
import { NavLink, Switch, Route, Redirect, useHistory } from 'react-router-dom'
import { BASIC_STATE } from '@redux/reducers/basic'
import { USERINFO, setToken, setUserInfo, logout } from '@redux/actions/basic'
import { companyName } from '@js/config'
import style from './Index.module.less'
import logoImg from '@imgs/logo.png'
import NewCase from './pages/NewCase/Index'
import CaseList from './pages/CaseList/Index'
import CasePayList from './pages/CasePayList/Index'
import CaseEdit from './pages/CaseEdit/Index'
import CaseSupplement from './pages/CaseSupplement/Index'
import Preview from './pages/CaseDetail/Preview'
import CaseDetail from './pages/CaseDetail/Index'
import { logoutRequest } from './ajax'
import { awaitWrap } from '@src/assets/js/tool'
import LogoutModal from './components/ModifyPasswordModal/Index'
import MeetingList from './pages/MeetingList/Index'

interface Props {
  userInfo: USERINFO
  history: History
  location: Location
  setToken: (v: string) => void
  setUserInfo: (v: USERINFO) => void
}

// 退出登录
async function logoutHandle (history: History, setToken: (v: string) => void, setUserInfo: (v: USERINFO) => void): Promise<void> {
  const hide = message.loading('退出中', 120)
  const [e] = await awaitWrap(logoutRequest())
  hide()
  if (e !== null) {
    return undefined
  }
  setToken('')
  setUserInfo({
    app_default: '',
    desktop: '',
    employeeid: '',
    id: '',
    is_auth: -1,
    mobile: '',
    realname: '',
    role_default: '',
    is_expire: 0,
    expire_info: ''
  })
  history.push('/login')
}

// 顶部头像菜单
function renderMenu (history: History, setToken: (v: string) => void, setUserInfo: (v: USERINFO) => void, setVisible: (v: boolean) => void): ReactElement {
  return (
    <Menu>
      <Menu.Item key={0} onClick={() => setVisible(true)}>修改密码</Menu.Item>
      <Menu.Item key={1} icon={<PoweroffOutlined className={style.error} />} onClick={async () => { await logoutHandle(history, setToken, setUserInfo) }}>退出</Menu.Item>
    </Menu>
  )
}

function Main ({ userInfo, history, location, setToken, setUserInfo }: Props): ReactElement {
  const [modifyVisible, setModifyVisible] = useState(false)
  const [expireInfo, setExpireInfo] = useState('')
  const [isExpire, setIsExpire] = useState(false)
  const dispatch = useDispatch()
  const h = useHistory()
  useEffect(() => {
    const c = userInfo.is_expire === 1
    setIsExpire(c)
    setExpireInfo(userInfo.expire_info)
    setModifyVisible(c)
  }, [userInfo])
  return (
    <div className={style.container}>
      <LogoutModal
        visible={modifyVisible}
        hide={() => {
          setModifyVisible(false)
          if (isExpire) {
            dispatch(logout())
            h.push('/login')
          }
        }}
        isExpire={isExpire}
        expireInfo={expireInfo}
      />
      <div className={style.header}>
        <div className={style['header-left']}>
          <span className={style['header-title']}>
            <img src={logoImg} />
            <span>{companyName}</span>
          </span>
        </div>

        <div className={style['header-menu']}>
          <Space size='large'>
            <NavLink activeClassName={style.active} to='/admin/new-case'>立案申请</NavLink>
            <NavLink activeClassName={style.active} to='/admin/case-list'>我的案件</NavLink>
            <NavLink activeClassName={style.active} to='/admin/meeting-list'>视频庭审</NavLink>
            <NavLink activeClassName={style.active} isActive={(match, location) => {
              return location.pathname.indexOf('/admin/case-pay-list') === 0
            }} to='/admin/case-pay-list/case-list'>案件缴费</NavLink>
          </Space>
        </div>

        <Dropdown overlay={renderMenu(history, setToken, setUserInfo, setModifyVisible)} trigger={['click']}>
          <span className={style['header-right']}>
            <span className={style['header-person__name']}>{userInfo.realname}</span>
            <Avatar icon={<UserOutlined />} />
          </span>
        </Dropdown>
      </div>
      <div className={style.body} id='body'>
        <Switch location={location}>
          <Route exact path='/admin/new-case' component={NewCase} />
          <Route exact path='/admin/case-list' component={CaseList} />
          <Route path='/admin/case-edit/:caseid' component={CaseEdit} />
          <Route path='/admin/case-supplement/:caseid' component={CaseSupplement} />
          <Route path='/admin/preview/:caseid' component={Preview} />
          <Route path='/admin/case-detail/:caseid' component={CaseDetail} />
          <Route exact path='/admin/case-pay-list*' component={() => <CasePayList location={location} history={history} />} />
          <Route exact path='/admin/meeting-list' component={MeetingList} />
          <Route exact path='/admin*' component={() => <Redirect to='/admin/case-list' />} />
        </Switch>
      </div>
      <div className={style.footer}>
        <Space>
          <span>©2020 service.xmac.org.cn</span>
          <span>闽ICP备12022941号-1</span>
          <span>技术支持：之初科技</span>
        </Space>
      </div>
    </div>
  )
}

export default connect((state: { basic: BASIC_STATE }) => {
  return {
    userInfo: state.basic.userInfo
  }
}, dispatch => {
  return {
    setToken: (v: string) => dispatch(setToken(v)),
    setUserInfo: (v: USERINFO) => dispatch(setUserInfo(v))
  }
})(Main)
