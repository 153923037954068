import { $freePost, $get } from '@js/request'
import { USERINFO } from '@src/redux/actions/basic'

interface LoginRes {
  token: string
  userInfo: USERINFO
}

export async function loginRequest (code: string, appid: string): Promise<LoginRes> {
  const res = await $freePost('/api/common/sso_login', { code, appid })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

// 获取案件列表
export async function getCaseListRequest (): Promise<number> {
  const res = await $get('/api/xd/case/findCaseByAccountid', {
    params: {
      current: 1,
      page_size: 1000
    }
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data.total
}
