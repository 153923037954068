import { CaseSchema } from '@src/components/Schema/CaseSchema'
import React, { ReactElement } from 'react'
import Defince from '../../components/Defince/Index'
import Court from '../../components/Court/Index'
import Effectiveness from '../../components/Effectiveness/Index'
import FaPiao from '../../components/FaPiao/Index'
import TuiFei from '../../components/TuiFei/Index'

export interface OperateItem {
  flow_name: string
  key: string
  orderno: number
  title: string
}

interface Props {
  data: CaseSchema
  list: OperateItem[]
}

function Main ({ data, list = [] }: Props): ReactElement {
  const keys = list.map(li => li.key)
  return (
    <div>
      {keys.includes('party_dabian') ? <Defince data={data} /> : <span />}
      {keys.includes('party_zuting') ? <Court data={data} /> : <span />}
      {keys.includes('party_shengxiaozhengming') ? <Effectiveness data={data} /> : <span />}
      {keys.includes('party_fapiao') ? <FaPiao data={data} /> : <span />}
      {keys.includes('party_tuifei') ? <TuiFei data={data} /> : <span />}
    </div>
  )
}

export default Main
