import { $freePost, $get } from '@js/request'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { USERINFO } from '@src/redux/actions/basic'

export async function loginRequest (token: string, caseid: string, source: string): Promise<{ userInfo: USERINFO, token: string, caseid?: string }> {
  const o: { [k: string]: any } = { token }
  if (typeof caseid === 'string' && caseid.length > 0) {
    o.caseid = caseid
  }
  if (typeof source === 'string' && source.length > 0) {
    o.source = source
  }
  const res = await $freePost('/api/hs/token_login', o)
  return res.data
}

// 获取案件详情请求请求
export async function getCaseDetailRequest (caseid: string): Promise<CaseSchema> {
  const res = await $get(`/api/xd/case/partyCaseRead/${caseid}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
