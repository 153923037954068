export const columns = [
  {
    title: '序号',
    name: 'no',
    dataIndex: 'no',
    width: '60px'
  },
  {
    title: '姓名',
    name: 'name',
    dataIndex: 'name',
    width: '150px'
  },
  {
    title: '职业分类',
    name: 'occupation',
    dataIndex: 'occupation',
    width: '150px'
  },
  {
    title: '擅长专业',
    name: 'cause_action',
    dataIndex: 'cause_action'
  },
  {
    title: '性别',
    name: 'gender',
    dataIndex: 'gender',
    width: '60px'
  },
  {
    title: '所在区域',
    name: 'area',
    dataIndex: 'area',
    width: '100px'
  }
]
