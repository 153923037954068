import { awaitWrap } from '@src/assets/js/tool'
import { setToken, setUserInfo } from '@src/redux/actions/basic'
import { message, Result, Spin } from 'antd'
import { ReactElement, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Dispatch } from 'redux'
import { loginRequest, getCaseDetailRequest } from './ajax'
import { History } from 'history'
import qs from 'query-string'
import style from './Index.module.less'
import _ from 'lodash'

async function getCaseAndNavigate (caseid: string, h: History): Promise<void> {
  const hide = message.loading('加载中', 120)
  const [e, d] = await awaitWrap(getCaseDetailRequest(caseid))
  hide()
  if (e === null && d !== null) {
    if (['未提交', '审核不通过', null, undefined, ''].includes(d.status)) {
      h.push(`/admin/case-edit/${caseid}`)
    } else {
      h.push(`/admin/case-detail/${caseid}`)
    }
  }
}

async function login (token: string, caseid: string, source: string, h: History, dispatch: Dispatch, setMessage: (v: string) => void, setLoading: (v: boolean) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(loginRequest(token, caseid, source))
  if (e !== null) {
    setLoading(false)
    setMessage(e.message)
  }
  if (e === null && d !== null) {
    dispatch(setUserInfo(d.userInfo))
    dispatch(setToken(d.token))
    setLoading(false)
    setTimeout(() => {
      if (typeof d.caseid === 'string' && d.caseid.length > 0) {
        getCaseAndNavigate(d.caseid, h).catch(e => console.error(e))
      } else {
        h.push('/admin/new-case')
      }
    }, 100)
  }
}

function Main (): ReactElement {
  const h = useHistory()
  const [params, setParams] = useState<{[k: string]: any}>({ token: '', caseid: '' })
  const dispatch = useDispatch()
  const [token, setToken] = useState('')
  const [caseid, setCaseid] = useState('')
  const [source, setSource] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    const o = qs.parse(location.search)
    if (_.isPlainObject(o)) {
      setLoading(false)
    }
    setParams(o)
  }, [location])
  useEffect(() => {
    if (typeof params.token === 'string') {
      setToken(params.token)
    } else {
      setMessage('token不能为空')
    }
    if (typeof params.caseid === 'string') {
      setCaseid(params.caseid)
    }
    if (typeof params.source === 'string') {
      setSource(params.source)
    }
  }, [params])
  useEffect(() => {
    if (typeof token === 'string' && token.length > 0) {
      login(token, caseid, source, h, dispatch, setMessage, setLoading).catch(e => console.error(e))
    }
  }, [token])
  return (
    <div>
      {loading && (
        <div className={style.loading}>
          <Spin size='large' />
        </div>
      )}
      {
        !loading && message && (
          <Result
            status='error'
            title='登录失败'
            subTitle={message}
          />
        )
      }
    </div>
  )
}

export default Main
