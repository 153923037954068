import { ReactElement } from 'react'
import style from './index.module.less'
import { FileSchema } from '@components/Schema/FileSchema'
import { download } from '@js/downloadRename'
import { limitStr } from '@js/tool'

interface Props {
  value?: FileSchema[]
}

function Main ({ value = [] }: Props): ReactElement {
  return (
    <div className={style.list}>
      {value.length > 0
        ? value.map((li, i) => {
          return (
            <span className={style.file} key={i}>
              <span>{i + 1}、</span>
              <a title={li.original_filename} onClick={() => download(li.path, li.original_filename, li.filename)}>{limitStr(li.original_filename, 15)}</a>
            </span>
          )
        })
        : '无'}
    </div>
  )
}

export default Main
