import { $get, $post } from '@js/request'
import { EffectiveSchema } from '@src/components/Schema/EffectiveSchema'

// 保存
export async function createEffective (data: {[k: string]: any}): Promise<void> {
  const res = await $post('/api/xd/item/fapiaoApply', data)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export async function postAgainRequest (data: {[k: string]: any}): Promise<void> {
  const res = await $post('/api/xd/item/fapiaoApplyAgain', data)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export async function getListRequest (caseid: string): Promise<EffectiveSchema[]> {
  const res = await $get('/api/xd/fapiao/readBycaseidAndUid', { caseid })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
