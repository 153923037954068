import { $post } from '@js/request'
import { compress } from '@src/assets/js/tool'

export async function sendSmsRequest (mobile: string): Promise<void> {
  const res = await $post('/api/common/sms', {
    mobile: compress(mobile),
    template_code: 'SMS_182920835'
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export async function modifyRequest (d: any): Promise<void> {
  const res = await $post('/api/common/edit_password', d)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
