import { FaPiaoSchema } from '@src/components/Schema/FaPiaoSchema'
import { ReactElement } from 'react'
import style from './Item.module.less'
import { formatDate } from '@src/assets/js/tool'
import FileList from '@components/FileList'

function filterEmpty (v: any): any {
  if (v === null || v === '' || v === undefined) {
    return '无'
  }
  return v
}

interface Props {
  data: FaPiaoSchema
}

function Main ({ data }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style.order}>1</div>
      <div>
        <div className={style.line}>
          <div className={style.label}>申请人</div>
          <div className={style.value}>
            <span>{data.operator}</span>
            <span className={style.date}>{formatDate(data.submit_datetime, 'YYYY-MM-DD HH:mm')}</span>
          </div>
        </div>
        <div className={style.line}>
          <div className={style.label}>仲裁地位</div>
          <div className={style.value}>{filterEmpty(data.applicant_type)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>证件类型</div>
          <div className={style.value}>{filterEmpty(data.applicant_license_type)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>证件号码</div>
          <div className={style.value}>{filterEmpty(data.applicant_license)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>手机号</div>
          <div className={style.value}>{filterEmpty(data.applicant_mobile)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>开票金额</div>
          <div className={style.value}>{filterEmpty(data.exvars_fee)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>开票类型</div>
          <div className={style.value}>{filterEmpty(data.subtype)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>申请事由</div>
          <div className={style.value}>{filterEmpty(data.content)}</div>
        </div>
        <div className={style.line}>
          <div className={style.label}>附件</div>
          <div className={style.value}>
            <FileList value={data.file} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Main
