import { ReactElement, useEffect, useState } from 'react'
import { Spin, Result, message } from 'antd'
import style from './Index.module.less'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import { loginRequest, getCaseListRequest } from './ajax'
import { awaitWrap } from '@src/assets/js/tool'
import { setSource, setToken, setUserInfo } from '@redux/actions/basic'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { History } from 'history'

async function login (code: string, appid: string, dispatch: Dispatch<any>, history: History, setLoading: (v: boolean) => void, setError: (v: string) => void): Promise<void> {
  setLoading(true)
  const [e, d] = await awaitWrap(loginRequest(code, appid))
  setLoading(false)
  if (e !== null) {
    setError(e.message)
  }
  if (e === null && d !== null) {
    dispatch(setToken(d.token))
    dispatch(setUserInfo(d.userInfo))
    dispatch(setSource('国际商事法庭'))
    message.success('登录成功', 1).then(null, null)
    if (d.userInfo.is_auth === 0) {
      history.push('/verification')
    } else {
      await getCaseList(history)
    }
  }
}

// 获取案件列表并决定跳转地址
async function getCaseList (history: History): Promise<void> {
  const hide = message.loading('加载中', 120)
  const [e, d] = await awaitWrap(getCaseListRequest())
  hide()
  if (e !== null) {
    history.push('/admin/case-list')
    return undefined
  }
  if (d === null || d !== 0) {
    history.push('/admin/case-list')
  } else {
    history.push('/admin/new-case')
  }
}

function Main (): ReactElement {
  const l = useLocation()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const h = useHistory()
  useEffect(() => {
    const s = qs.parse(l.search)
    if (typeof s.code === 'string' && typeof s.appid === 'string') {
      login(s.code, s.appid, dispatch, h, setLoading, setError).catch(e => console.error(e))
    } else {
      setError('code和appid不能为空')
    }
  }, [l])
  if (loading) {
    return (
      <div className={style.loading}>
        <Spin size='large' />
        <span className={style['loading-text']}>登录中</span>
      </div>
    )
  }
  if (error !== '') {
    return (
      <div >
        <Result status='error' title='登录失败' subTitle={error} />
      </div>
    )
  }
  return (
    <div>123</div>
  )
}

export default Main
