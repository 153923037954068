import React, { ReactElement, useMemo } from 'react'
import Header from '../Header/Index'
import icon from '@imgs/evidence-icon.png'
import style from './Index.module.less'
import { EvidenceSchema } from '@src/components/Schema/EvidenceSchema'
import { PlusOutlined } from '@ant-design/icons'
import PicList from '../PicList/Index'
import { Button } from 'antd'
import { useUserInfo } from '@src/hooks/userInfo'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import { formatDate } from '@src/assets/js/tool'
import { useHistory } from 'react-router-dom'

interface Props {
  data: EvidenceSchema[]
  caseDetail: CaseSchema
}

function Item ({ data, index }: { data: EvidenceSchema, index: number }): ReactElement {
  return (
    <div>
      <div className={style.item}>
        <div className={style.order}>
          <span>{index + 1}</span>
        </div>
        <div className={style.content}>
          <div className={style['content-title']}>
            <div>{data.submittal} - {data.name}</div>
            <div className={style['content-time']}>{formatDate(data.create_datetime, 'YYYY-MM-DD')}</div>
          </div>
          <div>{data.proof}</div>
        </div>
      </div>
      <PicList data={data.file} />
    </div>
  )
}

function Main ({ data, caseDetail }: Props): ReactElement {
  const h = useHistory()
  const userInfo = useUserInfo()
  const isApplicant = useMemo(() => {
    return caseDetail.applicants.find(li => li.accountid === userInfo.id) !== undefined
  }, [userInfo, caseDetail])
  const isRespondent = useMemo(() => {
    return caseDetail.respondents.find(li => li.accountid === userInfo.id) !== undefined
  }, [userInfo, caseDetail])
  const isApplicantAgent = useMemo(() => {
    return caseDetail.applicant_agents.find(li => li.accountid === userInfo.id) !== undefined
  }, [userInfo, caseDetail])
  const isRespondentAgent = useMemo(() => {
    return caseDetail.respondents_agents.find(li => li.accountid === userInfo.id) !== undefined
  }, [userInfo, caseDetail])
  const aList = useMemo(() => {
    if (data instanceof Array) {
      return data.filter(li => ['申请人', '申请人代理人'].includes(li.submittal_type))
    }
    return []
  }, [data])
  const bList = useMemo(() => {
    if (data instanceof Array) {
      return data.filter(li => ['被申请人', '被申请人代理人'].includes(li.submittal_type))
    }
    return []
  }, [data])
  if (!(data instanceof Array) || data.length === 0) {
    return <span />
  }
  return (
    <div className={style.container}>
      <Header icon={icon} title='证据材料' />
      {
        (isApplicant || isApplicantAgent || aList.length > 0) && (
          <div className={style.inner}>
            <div className={style.title}>
              <span className={style['title-text']}>申请人证据</span>
              {(isApplicant || isApplicantAgent) && <Button icon={<PlusOutlined />} onClick={() => h.push(`/admin/case-supplement/${caseDetail.id}/evidence`)}>补充证据</Button>}
            </div>
          </div>
        )
      }
      {
        aList.map((li, i) => {
          return <Item key={i} data={li} index={i} />
        })
      }
      {
        (isRespondent || isRespondentAgent || bList.length > 0) && (
          <div className={style.inner}>
            <div className={style.title}>
              <span className={style['title-text']}>被申请人证据</span>
              {(isRespondent || isRespondentAgent) && <Button icon={<PlusOutlined />} onClick={() => h.push(`/admin/case-supplement/${caseDetail.id}/evidence`)}>补充证据</Button>}
            </div>
          </div>
        )
      }
      {
        bList.map((li, i) => {
          return <Item key={i} data={li} index={i} />
        })
      }
    </div>
  )
}

export default Main
