import React, { useReducer, useEffect, useState } from 'react'
import style from './Index.module.less'
import bgImg from '@imgs/logoBg.png'
import logoImg from '@imgs/logo.png'
import LoginForm from './components/LoginForm'
import { reducer, initialState } from './reducer'
import { companyName, appName } from '@js/config'
import { RouteComponentProps } from 'react-router-dom'
import { Space } from 'antd'
import { awaitWrap } from '@src/assets/js/tool'
import { getVersionRequest } from './ajax'
async function initVersion (setVersion: (v: string) => void): Promise<void> {
  const [e, d] = await awaitWrap(getVersionRequest())
  if (e === null && d !== null) {
    setVersion(`v ${d.version}`)
  }
}

function Main ({ history }: RouteComponentProps): React.ReactElement {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [version, setVersion] = useState('')
  useEffect(() => {
    initVersion(setVersion).then(null, null)
  }, [])
  return (
    <div className={style.container}>
      <div className={style['body-right']}>
        <img src={bgImg} />
      </div>
      <div className={style.content}>
        <div className={style['logo-line']}>
          <a href='http://service.xmac.org.cn/' className={style['logo-line-inner']}>
            <img className={style['logo-img']} src={logoImg} />
            <span>{companyName}</span>
            <span className={style['logo-point']} />
            <span>{appName}</span>
          </a>
        </div>
        <div className={style['form-container']}>
          <LoginForm state={state} dispatch={dispatch} history={history} />
        </div>
      </div>
      <div className={style.foot}>
        <Space>
          <span>{companyName}</span>
          <span>©2020 service.xmac.org.cn</span>
          <span>闽ICP备12022941号-1</span>
          <span>技术支持：之初科技</span>
          <span>{version}</span>
        </Space>
        <a href="https://xyt.xcc.cn/getpcInfo?sn=1636659736130494464&language=CN&certType=8&url=*.xmac.org.cn" className={style['other-power']} target="_blank">
          <embed src="https://program.xinchacha.com/web/1636659736130494464=*.xmac.org.cn.svg"width="103" height="38" type="image/svg+xml"/>
        </a>
      </div>
    </div>
  )
}

export default Main
