import React, { ReactElement, useState, useEffect } from 'react'
import { ClockCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { History } from 'history'
import style from './Index.module.less'
import { MeetSchema } from '@components/Schema/MeetSchema'
import { Button, message } from 'antd'

interface ItemProps {
  data: MeetSchema
  history: History
}

// 值是否存在
function isExist (v: any): boolean {
  return v !== null && v !== undefined
}

// 获取案件状态和样式
function getStatusAndClassNames (s: string): { status: string, name: string } {
  const name = [style['item-status']]
  if (['会议开始'].includes(s)) {
    name.push(style.wait)
  } else if (s === '会议结束') {
    name.push(style.pass)
  }
  return {
    status: s,
    name: name.join(' ')
  }
}

// 渲染案号
function CaseNo ({ s }: { s: string }): ReactElement {
  if (!isExist(s)) {
    return <span />
  }
  return <span className={style['item-caseno']}>案号 {s}</span>
}

function getTime (start: number, end: number): string {
  const year = moment(start * 1000).format('YYYY-MM-DD')
  const st = moment(start * 1000).format('HH:mm')
  const et = moment(end * 1000).format('HH:mm')
  return `${year} ${st} ~ ${et}`
}

function toMeet (d: MeetSchema): void {
  if (typeof d.guest_joinuri !== 'string' || d.guest_joinuri.length === 0) {
    message.warning('视频庭审地址不存在').then(null, null)
    return undefined
  }
  window.open(d.guest_joinuri, '_blank')
}

function Main ({ data: d, history }: ItemProps): ReactElement {
  const [name, setName] = useState(style['item-status'])
  const [status, setStatus] = useState(d.meeting_status)
  const [time, setTime] = useState('')
  useEffect(() => {
    const o = getStatusAndClassNames(d.meeting_status)
    setName(o.name)
    setStatus(o.status)
    setTime(getTime(d.begin_datetime, d.end_datetime))
  }, [d])
  return (
    <div className={style.item} key={d.id}>
      <div className={style['item-no']}>{d.order}</div>
      <div className={style['item-header']}>
        <div className={style['item-header__left']}>
          <CaseNo s={d.caseno_desc} />
          <span className={name}>
            <ClockCircleOutlined className={style['item-status-icon']} />
            <span>{status}</span>
          </span>
        </div>
        <div className={style['item-header__right']}>
          {d.meeting_status === '会议开始' && <Button onClick={() => toMeet(d)}>进入会议</Button>}
        </div>
      </div>
      <div className={style['item-other']}>
        <div className={style['item-other-line']}>
          <span className={style['item-other-line-cell']}>用途：{d.use}</span>
          <span className={style['item-other-line-cell-lg']}>开庭日期：{time}</span>
        </div>
      </div>
    </div>
  )
}

export default Main
