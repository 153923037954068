// import React from 'react'
// import { Redirect } from 'react-router-dom'
import Admin from '@src/pages/Admin/Index'
import Login from '@src/pages/Login/Index'
import SSOLogin from '@src/pages/SSOLogin/Index'
import Verification from '@src/pages/Verification/Index'
import TokenLogin from '@src/pages/TokenLogin/Index'
import LoginValidate from '@src/pages/LoginValidate/Index'
import { routerConfigs } from './registerRouter'

interface Page {
  path: string
  component: React.FC<any>
  auth: boolean
  checkeAuth?: boolean
}

export const RouterConfig: Page[] = [
  {
    path: '/admin*',
    component: Admin,
    auth: true,
    checkeAuth: true
  },
  {
    path: '/login',
    component: Login,
    auth: false
  },
  {
    path: '/ssologin',
    component: SSOLogin,
    auth: false
  },
  {
    path: '/verification',
    component: Verification,
    auth: true
  },
  {
    path: '/casedocking',
    component: TokenLogin,
    auth: false
  },
  {
    path: '/login-validate',
    component: LoginValidate,
    auth: false
  },
  ...routerConfigs
]
