import { $get, $put } from '@js/request'
import _ from 'lodash'
import { CaseSchema } from '@components/Schema/CaseSchema'
import { USERINFO } from '@src/redux/actions/basic'

// 获取案件详情请求请求
export async function getCaseDetailRequest (caseid: string): Promise<CaseSchema> {
  const res = await $get(`/api/xd/case/partyCaseRead/${caseid}`)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}

export interface SubData { [v: string]: any }

// 更新案件数据
export async function updateCaseRequest (caseDetail: CaseSchema, modifyData: SubData, userInfo: USERINFO): Promise<void> {
  const d = _.cloneDeep(caseDetail)
  const id = d.id
  d.claim.items.forEach(li => {
    delete li.id
  })
  const o: SubData = {
    claim: d.claim,
    submittal: userInfo.realname,
    submittalid: userInfo.id,
    basis: d.basis,
    cause_action: d.cause_action,
    status: d.status,
    basis_fileid: d.basis_fileid,
    ...modifyData
  }

  const res = await $put(`/api/xd/case/${id}`, o)
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data
}
