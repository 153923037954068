import React, { ReactElement, useEffect, useState } from 'react'
import { CaseSchema } from '@src/components/Schema/CaseSchema'
import style from './Index.module.less'
import { getListRequest } from './ajax'
import { FaPiaoSchema } from '@src/components/Schema/FaPiaoSchema'
import { awaitWrap, decompress } from '@src/assets/js/tool'
import { Button } from 'antd'
import List from './components/Item/Index'
import { useHistory } from 'react-router-dom'
import HistoryModal from './components/HistoryModal/Index'

interface Props {
  data: CaseSchema
}

// 获取列表
async function initList (caseid: string, setList: (v: FaPiaoSchema[]) => void): Promise<void> {
  const [e, d] = await awaitWrap(getListRequest(caseid))
  if (e === null && d !== null) {
    if (d instanceof Array) {
      d.forEach(li => {
        if (typeof li.applicant_license === 'string' && li.applicant_license !== '') {
          li.applicant_license = decompress(li.applicant_license)
        }
        if (typeof li.applicant_mobile === 'string' && li.applicant_mobile !== '') {
          li.applicant_mobile = decompress(li.applicant_mobile)
        }
        if (li.actions instanceof Array) {
          li.actions.forEach(o => {
            if (typeof o.applicant_license === 'string' && o.applicant_license !== '') {
              o.applicant_license = decompress(o.applicant_license)
            }
            if (typeof o.applicant_mobile === 'string' && o.applicant_mobile !== '') {
              o.applicant_mobile = decompress(o.applicant_mobile)
            }
          })
        }
      })
    }
    setList(d)
  }
}

function Main ({ data }: Props): ReactElement {
  const h = useHistory()
  const [list, setList] = useState<FaPiaoSchema[]>([])
  const [visible, setVisible] = useState(false)
  const [detail, setDetail] = useState<FaPiaoSchema | null>(null)
  useEffect(() => {
    if (data !== null) {
      initList(data.id, setList).catch(e => console.log(e))
    }
  }, [data])
  return (
    <div id='party_fapiao'>
      <HistoryModal visible={visible} hide={() => { setVisible(false) }} data={detail} />
      <div className={style.header}>开具发票</div>
      {
        list.map((li, i) => {
          return <List caseid={data.id} data={li} key={li.id} index={i} setDetail={setDetail} setVisible={setVisible} />
        })
      }
      <div className={style['btn-line']}>
        <Button onClick={() => h.push(`/admin/case-detail/${data.id}/fapiao`)}>
          {list.length > 0 ? '再申请开具发票' : '申请开具发票'}
        </Button>
      </div>
    </div>
  )
}

export default Main
