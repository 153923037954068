import React, { ReactElement } from 'react'
import { Button, Alert } from 'antd'
import { History } from 'history'
import { match } from 'react-router-dom'
import { CaseSchema } from '@components/Schema/CaseSchema'
import style from './Index.module.less'
import logo from '@imgs/logo.png'
import Party from './components/Party/Index'
import Agent from './components/Agent/Index'
import Accord from './components/Accord/Index'
import ApplyDoc from './components/ApplyDoc/Index'
import CauseAction from './components/CauseAction/Index'
import Claim from './components/Claim/Index'
import Statement from './components/Statement/Index'
import Evidence from './components/Evidence/Index'
import { validateCase } from './tool'

interface Params {
  caseid: string
}

interface Props {
  history: History
  match: match<Params>
  caseDetail: CaseSchema
}

// 前往预览
function previewHandle (caseDetail: CaseSchema, history: History): void {
  if (validateCase(caseDetail)) {
    history.push(`/admin/preview/${caseDetail.id}`)
  }
}

function renderUnpassReason (d: CaseSchema): ReactElement {
  if (typeof d.audit_complete_remark === 'string') {
    return (
      <Alert
        message="审核不通过"
        description={d.audit_complete_remark}
        type="warning"
        showIcon
      />
    )
  }
  return <span />
}

function Main ({ caseDetail, history, match }: Props): ReactElement {
  return (
    <div className={style.container}>
      <div className={style['title-line']}>
        <img src={logo} className={style.logo} />
        <span className={style.title}>仲裁申请书</span>
      </div>
      {renderUnpassReason(caseDetail)}
      <Party caseDetail={caseDetail} type='applicant' history={history} />
      <Agent caseDetail={caseDetail} type='applicant' history={history} />
      <Party caseDetail={caseDetail} type='respondent' history={history} />
      <Accord caseDetail={caseDetail} history={history} />
      <ApplyDoc caseDetail={caseDetail} history={history} />
      <Claim caseDetail={caseDetail} history={history} />
      <Statement caseDetail={caseDetail} history={history} />
      <CauseAction caseDetail={caseDetail} history={history} />
      <Evidence caseDetail={caseDetail} history={history} />
      <div className={style['btn-line']}>
        <span>预览提交</span>
        <div className={style['btn-box']}>
          <Button type='primary' onClick={() => previewHandle(caseDetail, history)}>预览提交</Button>
        </div>
      </div>
    </div>
  )
}

export default Main
