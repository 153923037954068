import { useSelector } from 'react-redux'
import { BASIC_STATE } from '@src/redux/reducers/basic'
import { USERINFO } from '@src/redux/actions/basic'

export function useIsSelf (id: string): boolean {
  const { userInfo } = useSelector((state: { basic: BASIC_STATE }) => {
    return {
      userInfo: state.basic.userInfo
    }
  })
  return userInfo.id === id
}

export function useUserInfo (): USERINFO {
  const { userInfo } = useSelector((state: { basic: BASIC_STATE }) => {
    return {
      userInfo: state.basic.userInfo
    }
  })
  return userInfo
}
