/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { message } from 'antd'

export function download (url: string, filename: string, othername?: string): void {
  getBlob(url, function (blob: any) {
    saveAs(blob, filename || othername || '')
  })
};

function getBlob (url: string, cb: (v: any) => void): void {
  const xhr = new XMLHttpRequest()
  message.loading({
    content: '下载中',
    key: 'download',
    duration: 60
  })
  xhr.open('GET', url, true)

  xhr.responseType = 'blob'

  xhr.onload = function () {
    message.destroy('download')
    message.success('下载成功', 1)
    if (xhr.status === 200) {
      cb(xhr.response)
    }
  }

  xhr.onprogress = function (v) {
    let t = v.total || 0
    let u = 'B'
    if (t > 1024) {
      t = t / 1024
      u = 'KB'
    }
    if (t > 1024) {
      t = t / 1024
      u = 'MB'
    }
    if (t > 1024) {
      t = t / 1024
      u = 'GB'
    }
    const b = `${t.toFixed(2)}${u}`
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    message.loading({
      content: `${b} 下载中 ${Math.floor(v.loaded / v.total * 10000) / 100}%`,
      key: 'download'
    })
  }

  xhr.onerror = function () {
    message.destroy('download')
    message.error('下载失败')
  }

  xhr.send()
}

function saveAs (blob: any, filename: string): void {
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (window.navigator.msSaveOrOpenBlob) {
    (navigator as any).msSaveBlob(blob, filename)
  } else {
    const link = document.createElement('a')

    const body = document.querySelector('body')

    link.href = window.URL.createObjectURL(blob)

    link.download = filename

    // fix Firefox

    link.style.display = 'none'

    if (body !== null) {
      body.appendChild(link)
      link.click()
      body.removeChild(link)
    }

    window.URL.revokeObjectURL(link.href)
  };
}
