import { $get } from '@js/request'
import { CaseSchema } from '@components/Schema/CaseSchema'

// 获取案件列表
export async function getCaseListRequest (searchText: string): Promise<CaseSchema[]> {
  const condition = []
  if (searchText !== '') {
    condition.push({ k: 'caseno_desc', o: 'like', v: `%${searchText}%` })
  }
  const res = await $get('/api/xd/case/findCaseByAccountid', {
    page_size: 1000,
    current: 1,
    condition: JSON.stringify(condition)
  })
  if (res.status !== 1) {
    throw new Error(res.message)
  }
  return res.data.list.map((li: CaseSchema, i: number) => {
    li.order = i + 1
    return li
  })
}
